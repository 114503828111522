import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import 'lazysizes'

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'

if (document.querySelector('.typ img[alt=""]')) {
  import(/* webpackChunkName: "contentAltFix" */ 'js/utils/contentAltFix')
}

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

//===============================================================/
//  =components
//===============================================================/

if (document.querySelector('.cookie-consent')) {
  import(/* webpackChunkName: "compCookieConsent" */ 'js/components/cookieConsent')
}

if (document.querySelector('.js-login')) {
  import(/* webpackChunkName: "componentLogin" */ 'js/components/login')
}

if (document.querySelector('.js-collapse')) {
  import(/* webpackChunkName: "collapse" */ 'js/components/collapse')
}

if (document.querySelector('.js-feature-carousel')) {
  import(/* webpackChunkName: "featureCarousel" */ 'js/components/featureCarousel')
}

if (document.querySelector('.js-resource-filter-form')) {
  import(/* webpackChunkName: "resourceListFilters" */ 'js/components/resource-list-filters')
}

if (document.querySelector('.js-resource-list-items')) {
  import(/* webpackChunkName: "resourceList" */ 'js/components/resource-list')
}

if (document.querySelector('.js-social-button-window')) {
  import(/* webpackChunkName: "socialShare" */ 'js/components/social-share')
}

if (document.querySelector('.js-subscription')) {
  import(/* webpackChunkName: "subscription" */ 'js/components/subscription')
}

if (document.querySelector('.js-author')) {
  import(/* webpackChunkName: "author" */ 'js/components/author')
}

if (document.querySelector('.js-progress-bar')) {
  import(/* webpackChunkName: "progressBar" */ 'js/components/progressBar')
}

//===============================================================/
//  =blocks
//===============================================================/

if (document.querySelector('.js-gallery-carousel')) {
  import(/* webpackChunkName: "imageGalleryBlock" */ 'js/blocks/imageGalleryBlock')
}

if (document.querySelector('.js-column-carousel')) {
  import(/* webpackChunkName: "threeColumnContentBlock" */ 'js/blocks/threeColumnContentBlock')
}

if (document.querySelector('.js-video-parent')) {
  import(/* webpackChunkName: "videoBlock" */ 'js/blocks/videoBlock')
}

if (document.querySelector('.js-trending')) {
  import(/* webpackChunkName: "trendingArticlesBlock" */ 'js/blocks/trendingArticlesBlock')
}

if (document.querySelector('.js-accordion')) {
  import(/* webpackChunkName: "accordionBlock" */ 'js/blocks/accordionBlock')
}

if (document.querySelector('.js-form-block')) {
  import(/* webpackChunkName: "formBlock" */ 'js/blocks/formBlock')
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.js-header-navigation')) {
  import(/* webpackChunkName: "nav" */ 'js/layouts/nav')
}

if (document.querySelector('.js-back-to-top-btn')) {
  import(/* webpackChunkName: "footer" */ 'js/layouts/footer')
}

//===============================================================/
//  =pages
//===============================================================/

// if (document.querySelector('.page-specific')) {
//   import(/* webpackChunkName: "pageNameHere" */ 'js/pages/pageNameHere')
// }
